<template>
  <div>

    <base-template
        v-if="isReady"
        ref="baseTemplate"
        :title="$t('Map')"
        :items="[]"
        :items-context="addressesContext"
        :available-filters="$store.getters['address/getAvailableFilters']"
        :payload.sync="payload"
        :module="module"
        :allow-add="false"
    >

    </base-template>

    <b-row>
      <b-col md="6">
        <field-address-by-query
            name="place"
            :model.sync="currentAddress"
            :displayComplement="false"
            :disabled="false"
        />
      </b-col>
      <b-col md="6">
        <b-form-group
            :label="capitalize($t('radius'))"
            label-for="v-first-name"
        >
          <vue-slider
              id="v-first-name"
              v-model="currentKilometers"
              :adsorb="true"
              :interval="20"
              :marks="true"
              class="mx-1"
              :min="0"
              :max="200"
              direction="ltr"
          />
        </b-form-group>

      </b-col>
    </b-row>

    <l-map
        style="height: 600px"
        :zoom="map.zoom"
        :center="map.center"
        ref="mapRef"
    >
      <l-tile-layer
          :url="map.url"
          :attribution="map.attribution"
      ></l-tile-layer>
      <v-marker-cluster v-if="displayByGroups">
        <marker-map
            v-for="marker in markers"
            :display-by-groups="displayByGroups"
            :marker="marker"
        />
      </v-marker-cluster>
      <marker-map
          v-else
          v-for="marker in markers"
          :display-by-groups="displayByGroups"
          :marker="marker"
      />

      <l-circle
          v-if="currentAddress != ''"
          :lat-lng="[currentAddress.latitude, currentAddress.longitude]"
          :radius="1000*currentKilometers"
          fill-color="#f03"
          :opacity="0.2"
      />
    </l-map>
  </div>
</template>

<script>
import { ref, computed, watch }                                        from '@vue/composition-api'
import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LIcon, LCircle } from 'vue2-leaflet'
import { Icon }                                                        from 'leaflet'
import ButtonEdit                                                      from '../../../components/button/Edit.vue'
import BaseTemplate                                                    from '../../../components/base3/Base.vue'
import ButtonRemove                                                    from '../../../components/button/Remove.vue'
import WorkFloCard                                                     from '../../../components/card/WorkFlo.vue'
import store                                                           from '../../../store'
import useAPI                                                          from '../../../utils/useAPI'
import Vue2LeafletMarkerCluster                                        from 'vue2-leaflet-markercluster'
import MarkerMap                                                       from './components/Marker.vue'
import FieldAddressByQuery
                                                                       from '../../../components/input/AddressAutosuggest.vue'
import VueSlider                                                       from 'vue-slider-component'
import { capitalize }                                                  from '../../../utils/filter'
// import 'vue-slider-component/theme/antd.css'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  components: {
    FieldAddressByQuery,
    MarkerMap,
    WorkFloCard, ButtonRemove, BaseTemplate, ButtonEdit,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LIcon,
    LCircle,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
    VueSlider
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const module = ref('addressesMap')
    const payload = ref({})
    const isReady = ref(false)
    const displayByGroups = ref(true)

    const mapRef = ref()
    const map = ref({
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 6,
      center: [47, 1.011],
    })

    const markers = ref([])
    const currentAddress = ref('')
    const currentKilometers = ref(100)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { addressesContext } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(payload, val => {
      isReady.value = true
      // fetchCompanies2Addresses(val)
      fetchAddresses(val)
          .then(response => {
            // console.log(response.data)
            markers.value = []

            populateMarkers(response.data.results)
          })
    }, { deep: true })

    watch(currentAddress, val => {
      console.log(val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchAddresses } = useAPI()

    const populateMarkers = (addresses) => {
      // By address
      addresses.forEach(address => {
        if (displayByGroups.value) {
          markers.value.push(
              {
                latLon: [address.latitude, address.longitude],
                address: address,
                company: address.company == null ? null : {
                  id: address.company.id,
                  name: address.company.name
                }
              }
          )
        } else {
          markers.value.push(
              {
                latLon: [address.latitude, address.longitude],
                address: address,
                company: address.company == null ? null : {
                  id: address.company.id,
                  name: address.company.name
                }
              }
          )
        }
      })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    store.dispatch('moduleView/getModuleViews', {
      per_page: 5000
    })
        .then((response) => {
          let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)
          payload.value = {
            filters: currentView.filters,
            kanbanSortBy: currentView.kanbanSortBy,
            kanbanColumnAmount: currentView.kanbanColumnAmount,
            page: 1,
            per_page: 5000,
            sorts: currentView.sorts,
          }
        })

    return {
      // Components

      // Data
      module,
      isReady,
      payload,

      mapRef,
      map,
      markers,
      displayByGroups,
      currentAddress,
      currentKilometers,

      // Computed
      addressesContext,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>
@import '../../../../node_modules/leaflet/dist/leaflet.css';

@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>